import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from 'react'
import { AppBar, Toolbar, Typography, Menu } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import NavMenu from './NavMenu'
import { Link } from 'gatsby'

const useStyles = makeStyles(theme => ({
  appbar: {
    backgroundColor: 'white',
    transition: 'all 0.5s ease',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: 60,
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
    },
  },
  logoText: {
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  companyName: {
    fontSize: 26,
    paddingRight: 10,
    paddingLeft: 10,
    fontWeight: 'bold',
    lineHeight: '100%',
    [theme.breakpoints.down('xs')]: {
      fontSize: 24,
    },
  },
  topbar: {
    backgroundColor: theme.palette.primary.main,
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  fixed: {
    fontSize: 14,
    position: 'fixed',
    top: 0,
    left: 0,
    transition: 'all 0.5s ease',
  },
  logo: {
    width: 60,
    transition: 'width 0.5s ease',
  },
  hideLogo: {
    width: 0,
    transition: 'width 0.5s ease',
  },
  mobileLink: {
    color: 'block',
    textDecoration: 'none',
    '& div': {
      padding: '5px 15px',
    },
    '&:visited': {
      color: 'black',
    },
    '&:hover div': {
      borderBottomWidth: 2,
      borderBottomColor: theme.palette.primary.main,
      borderBottomStyle: 'solid',
    },
  },
  active: {
    color: `${theme.palette.primary.main} !important`,
    '& div': {
      borderBottomWidth: 2,
      borderBottomColor: theme.palette.primary.main,
      borderBottomStyle: 'solid',
    },
  },
  fullWidthMenu: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  isoLogo: {
    width: 160,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    transition: 'width 0.5s ease',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  hideISOLogo: {
    width: 0,
    transition: 'width 0.5s ease',
  },
}))

const Header = () => {
  const classes = useStyles()
  const [scrollY, setScrollY] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null)
  const appbarRef = useRef(null)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      function handleOnScroll() {
        setScrollY(window.scrollY)
      }

      window.addEventListener('scroll', handleOnScroll, true)

      return () => {
        window.removeEventListener('scroll', handleOnScroll)
      }
    }
  }, [])

  return (
    <>
      <AppBar className={`${classes.appbar} ${scrollY > 100 ? classes.fixed : ''}`} ref={appbarRef}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.logoContainer}>
            <img
              alt='logo'
              src={require('../images/almag-logo.png')}
              style={{ height: 50, marginBottom: 0 }}
              className={`${classes.logo}`}
            />
            <div className={classes.logoText}>
              <div>
                <Typography className={classes.companyName}>ALMAG SDN BHD</Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  width: 150,
                  paddingLeft: 8,
                  paddingRight: 8,
                }}
              >
                {/* <Typography variant="caption" style={{ fontSize: 10 }}>
                  (144982 - V)
                </Typography> */}
                <Typography variant='caption' style={{ fontSize: 10 }}>
                  Since 1986
                </Typography>
              </div>
            </div>
          </div>
          <div style={{ color: 'black' }}>
            <NavMenu
              onClickMobileMenu={() => {
                setAnchorEl(appbarRef.current)
              }}
            />
          </div>
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        classes={{ paper: classes.fullWidthMenu }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClick={() => setAnchorEl(null)}
      >
        <Link to='/' activeClassName={classes.active} className={classes.mobileLink}>
          <div>Home</div>
        </Link>
        <Link to='/about-us' activeClassName={classes.active} className={classes.mobileLink}>
          <div>About Us</div>
        </Link>
        <Link to='/products' activeClassName={classes.active} className={classes.mobileLink}>
          <div>Product</div>
        </Link>
        <Link to='/enquiry' activeClassName={classes.active} className={classes.mobileLink}>
          <div>Enquiry</div>
        </Link>
      </Menu>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
